.UATabs {
  @include theme-aware("background-color", "tabs-background", 0.12);
  padding: 2px 7px;
  border-radius: 8px;
  margin-bottom: 22px;

  .tab-item {
    font-size: 14px;
    line-height: 18px;
    z-index: 2;
    padding: 14px 0;

    @include media-breakpoint-up(md) {
      font-size: 16px;
      line-height: 20px;
    }

    &.active {
      font-weight: $font-weight-semibold;
    }
  }

  .tab-line {
    @include theme-aware("background-color", "color-white", 1);
    z-index: 1;
    border-radius: 6px;
    width: calc(50% - 2px);
    height: calc(100% - 4px);
    top: 2px;
    left: 2px;
    box-shadow: 0px 3px 1px 0px #0000000a;
    box-shadow: 0px 3px 8px 0px #0000001f;
    transition: left 200ms ease;
  }
}
