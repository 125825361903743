.UAFollowUser {
  .action-button {
    font-size: 16px;
    line-height: 20px;
    border-radius: 8px;
    font-weight: $font-weight-semibold;
    border: none;
    @include theme-aware("background-color", "color-sec", 1);
    transition: all 200ms;
  }

  .follow {
    padding: 12px 24px;

    &[data-is-followed="true"] {
      @include theme-aware("background-color", "color-gray-light", 1);
      @include theme-aware("color", "color-pri", 1);
    }
  }

  .dm {
    padding: 12px 14px;
  }
}
