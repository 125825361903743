.UAUserWorkouts {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
  column-gap: 14px;
  row-gap: 24px;

  .workout {
    padding: 18px 14px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    @include theme-aware("background-color", "color-light", 1);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    height: 130px;
    width: 100%;

    .workout-image {
      img {
        height: 50px;
      }
    }

    .workout-title {
      font-size: 14px;
      line-height: 20px;
      font-weight: $font-weight-semibold;
    }
  }
}
