.Subscription {
  .inner-section {
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }

    .right {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      column-gap: 35px;
      row-gap: 30px;
    }

    .info-holder {
      .inner-title {
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 12px;
      }

      .info {
        font-size: 15px;
        line-height: 18px;
      }
    }
  }

  .past-subscriptions-holder {
    .past-subscriptions-titles {
      grid-template-columns: 60% 40%;
      padding: 0 20px;
      row-gap: 10px;
      margin-bottom: 5px;

      @include media-breakpoint-up(sm) {
        grid-template-columns: 48% 18% 17% 17%;
        column-gap: 10px;
      }

      @include media-breakpoint-up(lg) {
        grid-template-columns: 46% 20% 16% 16%;
      }

      h6 {
        margin: 0;
        font-size: 12px;
        line-height: 18px;
        font-weight: $font-weight-semibold;
        white-space: nowrap;
      }
    }

    .past-subscriptions-list {
      .subscription {
        @include theme-aware("background-color", "color-white", 1);
        border-radius: 8px;
        padding: 15px 20px;
        grid-template-columns: 60% 40%;
        row-gap: 10px;
        margin-bottom: 5px;

        @include media-breakpoint-up(sm) {
          grid-template-columns: 48% 18% 17% 17%;
          column-gap: 10px;
        }

        @include media-breakpoint-up(lg) {
          grid-template-columns: 46% 20% 16% 16%;
        }

        .subscription-info {
          font-size: 13px;
          line-height: 16px;

          @include media-breakpoint-up(md) {
            font-size: 15px;
            line-height: 18px;
          }
        }
      }
    }
  }

  .cards-holder {
    margin-bottom: 30px;

    .credit-card {
      box-shadow: 0px 34px 16px -11px #0b272812;
      border: 1px solid;
      @include theme-aware("border-color", "color-gray", 1);
      @include theme-aware("background-color", "color-white", 1);
      border-radius: 8px;
      padding: 14px 14px 14px 23px;
      height: 175px;

      @include media-breakpoint-up(md) {
        max-width: 50%;
      }

      .card-info-holder {
        .card-info {
          h6 {
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 20px;
          }

          .card-number {
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.3em;
            margin-bottom: 12px;
          }

          .card-exp {
            font-size: 14px;
            line-height: 20px;
            margin-right: 0.3em;
          }
        }
      }
    }
  }

  .add-card-button {
    font-size: 16px;
    line-height: 20px;
  }
}

.add-new-card {
  padding: 30px 20px 26px;
  @include theme-aware("background-color", "color-white", 1);

  .title {
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 45px;
  }

  .UAInput {
    margin-bottom: 53px;

    label {
      font-size: 12px;
      font-weight: $font-weight-semibold;
      line-height: 18px;
      left: 0;
    }
  }

  .UAButton {
    font-size: 16px;
    font-style: italic;
    font-weight: 500;
    line-height: 15px;
    padding: 12px 55px;
    margin-top: -20px;
    margin-bottom: 20px;
  }

  .cancel-button {
    font-size: 14px;
    line-height: 20px;
  }
}

.UAModal {
  &.add-new-card-modal {
    .content {
      max-width: 345px;
    }
  }
}
