.ContentLanguageSelect {
  width: 100%;

  .UASelect__container {
    max-width: 100%;

    @include media-breakpoint-up(md) {
      max-width: 35%;
    }
  }

  .UASelect__option:hover {
    @include theme-aware("background-color", "color-green", 0.5);
  }

  .UASelect__option--is-focused {
    background-color: transparent;
  }

  .UASelect__option--is-selected {
    @include theme-aware("background-color", "color-green", 1);
    color: black;
  }
}
