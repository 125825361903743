* {
  outline: none;
  &:not(input):not(p)::selection {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
  }
}

html {
  font-size: $font-size;
  line-height: 1;

  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

body {
  -webkit-overflow-scrolling: touch;
  font-family: $font-family;
  font-weight: $font-weight-regular;
  font-size: $font-size;
  line-height: 1;

  max-width: 100vw;
  height: 100vh;

  @include theme-aware("background", "color-background");
  @include theme-aware("color", "color-text");

  transition: background 200ms, color 200ms;

  @include media-breakpoint-down(xs) {
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
  }
}

.App {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.main {
  flex: 1;
}

.container {
  max-width: 1147px;
}

a {
  color: inherit;
  text-decoration: none;
  outline: none;

  &:hover,
  &:active,
  &:visited,
  &:focus {
    color: inherit;
    text-decoration: none;
  }
}

p {
  &::selection {
    @include theme-aware("color", "color-selection-text");
    @include theme-aware("background", "color-selection-bg");
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  &::selection {
    @include theme-aware("color", "color-selection-text");
    @include theme-aware("background", "color-selection-bg");
  }
}

// ul {
//   margin: 0;
//   padding: 0;
//   list-style: none;
//   li {
//     margin: 0;
//     padding: 0;
//   }
// }

label {
  margin: 0;
  padding: 0;
  cursor: pointer;
}

input,
select,
textarea {
  border: none;
  outline: none;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;

  &::selection,
  *::selection {
    @include theme-aware("color", "color-selection-text");
    @include theme-aware("background", "color-selection-bg");
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    @include theme-aware(
      "-webkit-text-fill-color",
      "color-text",
      1,
      "!important"
    );
    transition: background-color 5000s ease-in-out 0s;
  }
}

textarea {
  min-height: 120px !important;
  max-height: 480px;
  resize: none;
}

select {
  cursor: pointer;

  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }
}

button {
  outline: none;

  &:focus,
  &:active,
  &:active {
    outline: none;
  }
}

.selectable {
  &::selection,
  *::selection {
    -webkit-user-select: auto !important;
    -moz-user-select: auto !important;
    -ms-user-select: auto !important;
    user-select: auto !important;

    @include theme-aware("color", "color-selection-text");
    @include theme-aware("background", "color-selection-bg");
  }
}

.not-selectable {
  &::selection,
  *::selection {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;

    color: inherit;
    background: transparent;
  }
}

.cursor {
  cursor: auto;
  &--pointer {
    cursor: pointer;
  }

  &--default {
    cursor: default;
  }

  &--grab {
    cursor: grab;
  }

  &--grabbing {
    cursor: grabbing;
  }

  &--disabled {
    cursor: not-allowed;
  }
}

.color {
  &--primary {
    @include theme-aware("color", "color-pri");
  }

  &--secondary {
    @include theme-aware("color", "color-sec");
  }

  &--gray {
    @include theme-aware("color", "color-gray");

    &--dark {
      @include theme-aware("color", "color-gray-dark");
    }

    &--light {
      @include theme-aware("color", "color-gray-light");
    }
  }

  &--white {
    @include theme-aware("color", "color-white");
  }

  &--black {
    @include theme-aware("color", "color-black");
  }

  &--green {
    @include theme-aware("color", "color-green");
  }

  &--red {
    @include theme-aware("color", "color-red");
  }

  &--success {
    @include theme-aware("color", "color-success");
  }

  &--error {
    @include theme-aware("color", "color-error");
  }
}

.bg {
  &--primary {
    @include theme-aware("background-color", "color-pri");
  }

  &--secondary {
    @include theme-aware("background-color", "color-sec");
  }

  &--gray {
    @include theme-aware("background-color", "color-gray");

    &--dark {
      @include theme-aware("background-color", "color-gray-dark");
    }

    &--light {
      @include theme-aware("background-color", "color-gray-light");
    }

    &--alt {
      @include theme-aware("background-color", "color-gray-alt");
    }
  }

  &--white {
    @include theme-aware("background-color", "color-white");
  }

  &--red {
    @include theme-aware("background-color", "color-red");
  }
}

.no-padding {
  padding: 0 !important;
}

.fw-medium {
  font-weight: $font-weight-medium !important;
}

.fw-semibold {
  font-weight: $font-weight-semibold !important;
}

.page-title {
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 27px;
}

.single-content-holder {
  padding: 30px 15px;
  @include theme-aware("background-color", "profile-background", 1);
  border-radius: 8px;

  @include media-breakpoint-up(md) {
    padding: 30px;
  }
}
