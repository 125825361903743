.UAButton {
  border: none;
  background: transparent;
  font-family: $font-family-sec;
  font-size: 22px;
  font-weight: $font-weight-bold;
  line-height: 22px;
  letter-spacing: 0em;
  border-radius: 50px;
  padding: 15px;

  &.border-alternative {
    border-radius: 8px;
  }

  &.text-alternative {
    font-family: $font-family;
    font-size: 18px;
    font-weight: $font-weight-semibold;
    line-height: 20px;
  }

  &.type {
    &--primary {
      @include theme-aware("background-color", "button-background-primary", 1);
      @include theme-aware("color", "button-text-primary", 1);
    }

    &--secondary {
      @include theme-aware(
        "background-color",
        "button-background-secondary",
        1
      );
      @include theme-aware("color", "button-text-secondary", 1);
    }

    &--dark {
      @include theme-aware("background-color", "color-text", 1);
      @include theme-aware("color", "color-white", 1);
    }

    &--danger {
      @include theme-aware("background-color", "button-background-danger", 1);
      @include theme-aware("color", "button-text-danger", 1);
    }

    &--gray {
      @include theme-aware("background-color", "color-gray", 1);
      @include theme-aware("color", "color-black", 1);
    }
  }

  &:disabled {
    @include theme-aware("background-color", "button-background-disabled", 1);
    @include theme-aware("color", "button-text-disabled", 1);
  }
}
