.Confirm {
  .page-desc {
    margin-bottom: 30px;
    max-width: 360px;
    font-size: 16px;
    line-height: 20px;
  }

  .phone {
    margin-bottom: 36px;
    font-size: 18px;
    font-weight: $font-weight-semibold;
    line-height: 20px;
  }

  .code-area {
    max-width: 300px;
    margin-bottom: 36px;

    input {
      width: 25%;
      height: 50px;
      min-width: 25px;
      max-width: 50px;
      margin: 0 10px;
      border-radius: 8px;
      font-family: "Open Sans";
      font-size: 26px;
      font-weight: $font-weight-bold;
      line-height: 35px;

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .resend-code-holder {
    margin-bottom: 36px;

    .time-left {
      margin-right: 10px;
      font-family: "Open Sans";
      font-size: 14px;
      line-height: 19px;
    }

    .resend-code {
      font-family: "Open Sans";
      font-size: 14px;
      font-weight: $font-weight-bolder;
      line-height: 19px;
    }
  }

  .UAButton {
    padding: 15px 54px;
    margin-bottom: 30px;
  }

  .go-back-link {
    font-size: 16px;
    line-height: 20px;
  }
}
