.UASubscribeModal {
  @include theme-aware("background-color", "color-white", 1);
  padding: 30px 20px;

  .subscribe-modal-title {
    font-size: 26px;
    line-height: 20px;
    margin-bottom: 30px;

    @include media-breakpoint-up(lg) {
      font-size: 36px;
      margin-bottom: 60px;
    }
  }
  .subscribe-content-holder {
    grid-template-columns: 1fr;

    @include media-breakpoint-up(md) {
      grid-template-columns: 45% 50%;
      column-gap: 40px;
    }

    .side {
      width: 100%;

      .side-title {
        font-size: 18px;
        line-height: 20px;
        margin-bottom: 20px;
      }

      .subscription-types {
        min-height: 235px;

        .subscription-type {
          @include theme-aware("background-color", "filter-background", 1);
          padding: 20px;
          border-radius: 8px;
          border: 4px solid transparent;
          transition: border 200ms;

          &:hover {
            @include theme-aware("border-color", "color-green", 0.2);
          }

          &[data-selected="true"] {
            @include theme-aware("border-color", "color-green", 1);
          }

          .top {
            .subscription-title {
              font-size: 15px;
              line-height: 18px;
            }

            .subscription-price {
              font-size: 15px;
              line-height: 18px;
            }
          }

          .bottom {
            margin-top: 20px;

            .subscription-description {
              font-size: 13px;
              line-height: 19px;
              letter-spacing: 0em;
              @include theme-aware("color", "color-gray-alt", 1);
            }
          }
        }
      }

      .card-info-area {
        .UAInput {
          margin-bottom: 53px;

          label {
            font-size: 12px;
            font-weight: $font-weight-semibold;
            line-height: 18px;
            left: 0;
          }
        }

        .last-card-info {
          .UAInput {
            margin-bottom: 20px;
          }
        }

        .UACheckbox {
          .text {
            font-size: 15px;
            font-weight: $font-weight-regular;
            @include theme-aware("color", "color-text", 1);
          }
        }

        .start-button {
          font-size: 20px;
          line-height: 20px;
          margin-top: 20px;
        }
      }

      &--right {
        margin-top: 30px;

        @include media-breakpoint-up(md) {
          margin-top: 0;
        }

        .side-title {
          margin-bottom: 45px;
        }
      }
    }
  }
}

.UAModal {
  &.subscription-modal {
    .content {
      @include media-breakpoint-up(lg) {
        max-width: 768px;
      }
    }
  }
}
