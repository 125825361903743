.UACheckbox {
  .checkbox {
    width: 20px;
    height: 20px;
    border: 1.5px solid;
    @include theme-aware("border-color", "color-black", 1);
    padding: 2px;
    border-radius: 6px;

    .box {
      @include theme-aware("background-color", "color-green", 1);
      border-radius: 3px;
      opacity: 0;
      transition: opacity 200ms;
    }
  }

  &[data-is-radio="true"] {
    .checkbox {
      border-radius: 50%;

      .box {
        border-radius: 50%;
      }
    }
  }

  .text {
    font-size: 16px;
    line-height: 20px;
    margin-left: 10px;
    font-weight: $font-weight-semibold;
  }

  input {
    &:checked + .checkbox {
      .box {
        opacity: 1;
      }
    }

    &:disabled {
      & + .checkbox {
        opacity: 0.5;
      }

      & + .text {
        pointer-events: none;
      }
    }
  }
}
