.UATopPlacePerson {
  .avatar-holder {
    width: 80px;
    height: 80px;

    @include media-breakpoint-up(md) {
      width: 92px;
      height: 92px;
    }

    img {
      object-fit: cover;
    }

    .rank {
      background: linear-gradient(
        180deg,
        #4c4c4b 0%,
        #9a9b9c 38.02%,
        #5d5d5d 73.44%,
        #a4a5a7 100%
      );
      padding: 3px;
      top: 0;
      left: 0;
      border-radius: 50%;
      width: 36px;
      height: 36px;

      span {
        z-index: 3;
        font-size: 16px;
      }

      &:before {
        content: "";
        width: calc(100% - 6px);
        height: calc(100% - 6px);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        @include theme-aware("background-color", "color-white", 1);
      }
    }

    .points {
      width: 88px;
      height: 30px;
      border-radius: 4px;
      @include theme-aware("background-color", "color-green", 1);
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 25%);
    }
  }

  .user-info {
    margin-top: 15px;

    .name {
      font-size: 14px;
      line-height: 20px;
    }

    .username {
      font-size: 12px;
      line-height: 18px;
    }
  }

  &[data-size="md"] {
    .avatar-holder {
      width: 90px;
      height: 90px;

      @include media-breakpoint-up(md) {
        width: 108px;
        height: 108px;
      }

      .rank {
        background: linear-gradient(
          180deg,
          #eabb96 0%,
          #6c4032 30.21%,
          #daab89 65.62%,
          #572c22 100%
        );
      }
    }
  }

  &[data-size="lg"] {
    .avatar-holder {
      width: 110px;
      height: 110px;

      @include media-breakpoint-up(md) {
        width: 121px;
        height: 121px;
      }

      .rank {
        background: linear-gradient(
          180deg,
          #caaf71 0%,
          #f2ecba 35.94%,
          #d3bc80 72.4%,
          #fcfbcb 100%
        );
      }
    }
  }
}
