.UASidebar {
  left: 0;
  z-index: -1;
  opacity: 0;
  transition: opacity 300ms ease 0ms, z-index 0ms ease 300ms;

  .overlay {
    @include theme-aware("background-color", "color-black", 0.4);
    z-index: 2;
  }

  .content-holder {
    z-index: 9;
    @include theme-aware("background-color", "filter-background", 1);
    max-width: 390px;
    transform: translateX(100%);
    transition: all 300ms;

    .content {
      padding: 10px;
    }
  }

  &[data-is-open="true"] {
    z-index: 999;
    opacity: 1;
    transition: opacity 300ms ease 10ms, z-index 0ms ease 0ms;

    .content-holder {
      transform: translateX(0);
    }
  }
}
