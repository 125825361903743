.SubPageLayout {
  &.pins {
    .page-content {
      background-color: transparent;
    }
  }
}

.Pins {
  .content-title {
    font-size: 38px;
    line-height: 38px;
    margin-bottom: 40px;
  }

  .categories {
    .category {
      margin-bottom: 20px;

      .category-title {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
}

.category-pins {
  grid-template-columns: repeat(4, 1fr);
  column-gap: 10px;
  row-gap: 10px;
  padding: 20px 8px;
  border-radius: 5px;

  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(8, 1fr);
    column-gap: 20px;
    row-gap: 0;
  }

  .pin {
    padding: 12px;
    border-radius: 5.5px;
    @include theme-aware("background-color", "color-white");

    .content {
      gap: 0.7rem;
      opacity: 0.4;

      &[data-is-earned="true"] {
        opacity: 1;
      }

      p {
        margin: 0;
        padding: 0;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
}
