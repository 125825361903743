.UALeadershipPerson {
  @include theme-aware("background-color", "color-white", 1);

  &[data-is-self="true"] {
    border-radius: 10px;
    border: 1px solid;
    @include theme-aware("border-color", "color-green", 1);
    padding: 14px 10px;
  }

  .left {
    .rank {
      margin-right: 12px;
    }

    .avatar {
      width: 40px;
      height: 40px;
      margin-right: 12px;

      img {
        object-fit: cover;
      }
    }

    .user-info-area {
      .user-name {
        font-size: 16px;
        margin-bottom: 0;
      }

      .username {
        font-size: 16px;
      }
    }
  }

  .right {
    .score-title {
      font-size: 12px;
      line-height: 18px;
    }

    .score {
      padding: 2px 12px;
      border-radius: 4px;
      @include theme-aware("background-color", "color-green", 1);
      font-size: 14px;
      line-height: 20px;
    }
  }
}
