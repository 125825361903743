.Loading {
  width: 60px;
  height: 25px;
  --_g: no-repeat
    radial-gradient(circle closest-side, rgb(0, 0, 0) 90%, rgba(0, 0, 0, 0));
  background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;
  background-size: calc(100% / 3) 50%;
  animation: loadingAnimation 800ms infinite linear;

  &.light {
    --_g: no-repeat
      radial-gradient(
        circle closest-side,
        rgb(255, 255, 255) 90%,
        rgba(255, 255, 255, 0)
      );
  }
}
@keyframes loadingAnimation {
  20% {
    background-position: 0% 0%, 50% 50%, 100% 50%;
  }
  40% {
    background-position: 0% 100%, 50% 0%, 100% 50%;
  }
  60% {
    background-position: 0% 50%, 50% 100%, 100% 0%;
  }
  80% {
    background-position: 0% 50%, 50% 50%, 100% 100%;
  }
}
