@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");

$font-family: "Montserrat", Arial, Helvetica, sans-serif;
$font-family-sec: "Montserrat", Arial, Helvetica, sans-serif;
$font-size: 14px;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-bolder: 800;
$font-weight-black: 900;
