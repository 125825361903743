.Workouts {
  .categories {
    padding: 32px 15px;
    @include theme-aware("background-color", "filter-background", 1);
    overflow-y: auto;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    margin-bottom: 27px;
    min-height: 144px;

    @include media-breakpoint-up(md) {
      padding: 32px 0;
    }

    .active-category-bg {
      @include theme-aware("background-color", "color-green-light", 1);
      width: 80px;
      height: 80px;
      border-radius: 8px;
      transition: all 200ms;
      z-index: 1;
      left: -100vw;
    }

    .category {
      width: 80px;
      height: 80px;
      border-radius: 8px;
      transition: all 200ms;
      margin: 0 10px;
      z-index: 2;

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }

      .category-image {
        width: 36px;
        height: 36px;
        margin-top: 10px;
      }

      .category-name {
        margin-top: 12px;
        font-size: 14px;
        font-weight: $font-weight-semibold;
        line-height: 14px;
        transition: all 200ms;
      }

      &[data-is-active="true"] {
        // @include theme-aware("background-color", "color-green-light", 1);
        .category-name {
          font-weight: $font-weight-bold;
          @include theme-aware("color", "color-gray-dark", 1);
        }
      }
    }
  }

  .page-header {
    margin-bottom: 18px;

    .selected-category-name {
      font-size: 18px;
      font-weight: $font-weight-bold;
      line-height: 20px;
    }

    .filter-button {
      img {
        margin-top: -2px;
        margin-right: 8px;
      }
      @include theme-aware("background-color", "color-green-light", 1);
      @include theme-aware("color", "color-text", 1);
      border-radius: 8px;
      padding: 12px 14px;
      border: none;
      font-size: 16px;
      font-weight: $font-weight-semibold;
      line-height: 20px;
      margin-left: 14px;
    }
  }

  .workouts-holder {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    row-gap: 20px;

    @include media-breakpoint-up(sm) {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 20px;
    }

    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(3, 1fr);
      column-gap: 26px;
      row-gap: 32px;
    }

    .workout {
      @include theme-aware("background-color", "color-light", 1);
      border-radius: 8px;
      box-shadow: 0px 4px 24px 0px rgba($color: #000000, $alpha: 0.06);
      transition: all 400ms;

      .workout-bg {
        background-image: url("../../../assets/static/workout-background.png");
        background-position: center right;
        background-repeat: no-repeat;
        z-index: 2;
      }

      .info-area {
        margin: 16px 19px 7px;
        z-index: 3;
        padding-right: 10px;

        .name {
          font-size: 13px;
          font-weight: $font-weight-bold;
          line-height: 20px;
          margin-bottom: 5px;
          @include theme-aware("color", "color-black", 1);
        }

        .details {
          margin-bottom: 22px;
          & > * {
            font-size: 10px;
            font-weight: $font-weight-semibold;
            line-height: 15px;
          }

          .duration {
            margin-right: 8px;
            @include theme-aware("color", "color-green-dark", 1);
          }
        }

        .actions {
          .bookmark-icon {
            margin-right: 14px;
          }

          .notification-icon {
            margin-right: 12px;
          }

          .difficulty {
            @include theme-aware("background-color", "color-green-dark", 1);
            @include theme-aware("color", "color-white", 1);
            border-radius: 20px;
            padding: 0 6px;
            font-size: 10px;
            line-height: 15px;
          }
        }

        .date {
          margin-top: 7px;
          font-size: 10px;
          line-height: 15px;
          @include theme-aware("color", "color-green-dark", 1);
        }
      }

      .right-bg {
        right: 0;
        top: 0;
        width: 35%;
        max-width: 130px;
        height: 100%;
        @include theme-aware("background-color", "color-green-light", 1);
        z-index: 1;
      }

      .free-badge {
        right: 0;
        top: 10px;
        z-index: 2;
        @include theme-aware("background-color", "color-red", 1);
        @include theme-aware("color", "color-white", 1);
        font-size: 10px;
        line-height: 15px;
        padding: 4px;
        box-shadow: 0px 2px 4px 0px rgba($color: #000000, $alpha: 0.25);
      }

      .image-area {
        width: 35%;
        max-width: 130px;
        z-index: 3;

        img {
          transform: translateX(calc(-50% + 15px));
        }
      }

      .play-icon {
        right: 0;
        bottom: 0;
        padding: 9px 14px 9px 18px;
        @include theme-aware("background-color", "color-black", 0.5);
        border-radius: 8px 0;
        z-index: 4;
        pointer-events: none;
      }

      &:hover {
        transform: translateY(-6px);
        box-shadow: 0px 9px 28px 0px rgba($color: #000000, $alpha: 0.06);
      }
    }
  }

  .no-workouts {
    font-size: 18px;
    font-weight: $font-weight-semibold;
    line-height: 20px;
    @include theme-aware("color", "color-gray-dark", 1);
  }

  .Filters {
    .filter {
      @include theme-aware("background-color", "color-white", 1);
      padding: 10px;
      border-radius: 8px;
      max-height: 42px;
      margin-bottom: 14px;

      .filter-title {
        margin-bottom: 20px;
        .title {
          font-size: 14px;
          line-height: 20px;
        }
        .right-side {
          .selected-filters {
            margin-right: 10px;

            .selected-filter {
              padding: 0 4px;
              @include theme-aware("background-color", "filter-background", 1);
              margin-right: 5px;
              font-size: 12px;
              line-height: 20px;
              border-radius: 4px;

              &:last-of-type {
                margin-right: 0;
              }
            }
          }
          .accordion-trigger {
            transform: rotate(0);
            transition: transform 200ms;
          }
        }
      }

      .filter-content {
        %filters-holder {
          grid-template-columns: repeat(3, 1fr);
          column-gap: 10px;
          row-gap: 10px;
        }

        %filter-holder {
          border-radius: 8px;
          @include theme-aware("background-color", "filter-background", 1);
          transition: all 200ms;

          &[data-is-selected="true"] {
            @include theme-aware("background-color", "color-green", 1);
          }
        }
        .durations {
          @extend %filters-holder;
          grid-auto-rows: minmax(100px, auto);

          .duration {
            @extend %filter-holder;

            .number {
              font-size: 28px;
              line-height: 20px;
              font-weight: $font-weight-medium;
              margin-bottom: 20px;
              margin-top: 10px;
            }

            .title {
              font-size: 12px;
              line-height: 20px;
            }
          }
        }

        .trainers {
          @extend %filters-holder;
          grid-auto-rows: minmax(110px, auto);

          .trainer {
            @extend %filter-holder;

            .photo {
              width: 64px;
              height: 64px;
              margin-bottom: 5px;
              margin-top: 5px;
            }

            .name {
              font-size: 12px;
              line-height: 20px;
            }
          }
        }

        .difficulties {
          @extend %filters-holder;
          grid-template-rows: minmax(110px, auto);

          .difficulty {
            @extend %filter-holder;

            .icon {
              margin-bottom: 16px;
              margin-top: 3px;
            }

            .name {
              font-size: 16px;
              line-height: 20px;
            }
          }
        }

        .regionals {
          @extend %filters-holder;
          grid-template-columns: repeat(2, 1fr);
          grid-auto-rows: minmax(50px, auto);

          .regional {
            @extend %filter-holder;

            .name {
              font-size: 16px;
              line-height: 20px;
            }
          }
        }

        .sorts {
          @extend %filters-holder;
          grid-template-columns: 1fr;
          grid-auto-rows: minmax(50px, auto);

          .sort {
            @extend %filter-holder;

            .name {
              font-size: 16px;
              line-height: 20px;
            }
          }
        }

        .content-languages {
          @extend %filters-holder;
          grid-template-columns: 1fr;
          grid-auto-rows: minmax(50px, auto);

          .content-language {
            @extend %filter-holder;

            .name {
              font-size: 16px;
              line-height: 20px;
            }
          }
        }
      }

      &[data-is-expanded="true"] {
        max-height: max-content;

        .filter-title {
          .accordion-trigger {
            transform: rotate(180deg);
          }
        }
      }
    }

    .apply-button {
      font-family: $font-family;
      font-size: 16px;
      font-weight: $font-weight-semibold;
      line-height: 20px;
      padding: 12px 0;
      border-radius: 8px;

      .label {
        margin: 0;
      }
    }
  }
}

.Workout {
  padding-bottom: 27px;

  .workout-info-area {
    padding: 0 24px 0 19px;
    background-position: center;
    background-size: cover;

    &:after {
      content: "";
      width: 100%;
      height: 70%;
      position: absolute;
      left: 0;
      bottom: 0;
      background: linear-gradient(
        180.79deg,
        rgba(255, 255, 255, 0) 18.83%,
        rgba(255, 255, 255, 0.69) 46.73%,
        #ffffff 65.22%
      );
    }

    & > * {
      z-index: 1;
    }

    .play-button {
      padding: 112px 0 89px;
    }

    .workout-title {
      font-size: 38px;
      font-weight: $font-weight-bold;
      line-height: 49px;
      margin-bottom: 1px;
      @include theme-aware("color", "color-black", 1);
    }

    .difficulty {
      font-size: 16px;
      font-weight: $font-weight-semibold;
      line-height: 20px;
      @include theme-aware("color", "color-text", 0.5);
    }

    .more-info {
      font-size: 18px;
      font-weight: $font-weight-semibold;
      line-height: 20px;
      margin-top: 14px;

      .duration {
        @include theme-aware("color", "color-green-dark", 1);
      }

      .trainer {
        @include theme-aware("color", "color-text", 0.5);
        margin-left: 22px;
      }
    }

    .bookmark-button {
      border-radius: 8px;
      box-shadow: 0px 4px 24px 0px rgba($color: #000000, $alpha: 0.06);
      padding: 15px;
      @include theme-aware("background-color", "color-white", 1);
    }

    .description {
      font-size: 16px;
      line-height: 20px;
      margin-top: 20px;
      margin-bottom: 6px;
      padding-bottom: 10px;
      @include theme-aware("color", "color-text", 0.5);
    }
  }

  .workout-content-area {
    padding: 24px;

    .tabs {
      grid-template-columns: repeat(2, 1fr);
      @include theme-aware("background-color", "tabs-background", 0.12);
      border-radius: 8px;
      margin-bottom: 32px;

      .selected-bg {
        box-shadow: 0px 3px 1px 0px #0000000a;
        box-shadow: 0px 3px 8px 0px #0000001f;
        @include theme-aware("background-color", "color-white", 1);
        left: 0;
        top: 0;
        border-radius: 6px;
        margin: 2px;
        width: calc(50% - 2px);
        height: calc((100% - 4px));
        transition: transform 200ms;
      }

      .tab {
        padding: 14px 5px;
        z-index: 2;
        font-size: 16px;
        font-weight: $font-weight-regular;
        line-height: 20px;
        transition: all 200ms;

        &.active {
          font-weight: $font-weight-semibold;
        }
      }
    }

    .tab-content {
      font-size: 16px;
      font-weight: $font-weight-regular;
      line-height: 20px;
      min-height: 100px;
      margin-bottom: 25px;
    }
  }

  .start-button {
    .UAButton {
      padding: 14px 34px;
    }
  }

  &.Live {
    padding-top: 48px;
    @include theme-aware("backgrouund-color", "modal-live-background", 1);

    .workout-info-area {
      &:after {
        display: none;
      }

      .workout-title {
        font-size: 38px;
        line-height: 38px;
        margin-bottom: 20px;
      }

      .cover {
        height: 275px;
        margin-bottom: 6px;

        img {
          object-fit: cover;
        }
      }

      .live-badge {
        font-size: 18px;
        line-height: 20px;
        @include theme-aware("background-color", "color-red", 1);
        padding: 9px 27px;
        border-radius: 8px;
        margin-bottom: 15px;
      }

      .date {
        font-size: 24px;
        line-height: 20px;
        @include theme-aware("color", "color-black", 1);
        margin-bottom: 15px;
      }

      .trainer {
        font-size: 18px;
        line-height: 20px;
      }
    }

    .workout-content-area {
      .time-left {
        font-size: 14px;
        line-height: 20px;
        width: 350px;
        padding: 16px 4px;
        border-radius: 8px;
        @include theme-aware("background-color", "color-white", 1);

        .countdown {
          font-size: 14px;
          line-height: 20px;
          @include theme-aware("color", "color-black", 1);
        }
      }

      .tab-content {
        min-height: 70px;
      }
    }
  }
}

.Watch {
  top: 0;
  left: 0;
  z-index: 9999;
  @include theme-aware("background-color", "color-gray-dark", 1);

  .overlay {
    z-index: 9999;
  }

  .seeking-loader {
    z-index: 9998;
    background-color: rgba($color: #000000, $alpha: 0.6);
  }

  .loading-content {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .action-feedback {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
    z-index: -1;
    transition: opacity 200ms, transform 200ms ease 210ms,
      z-index 0ms ease 210ms;

    img {
      width: 48px;
      height: 48px;
    }

    &[data-is-in="true"] {
      transform: translate(-50%, -50%) scale(1.5);
      opacity: 0.6;
      z-index: 99999;
      transition: opacity 200ms ease 10ms, transform 200ms ease 10ms,
        z-index 0ms ease 0ms;
    }
  }

  .video-top-section {
    padding: 30px 40px;
    left: 0;
    top: 0;
    background: linear-gradient(
      180deg,
      rgba(35, 31, 32, 0.7) 23.75%,
      rgba(35, 31, 32, 0.48) 57.56%,
      rgba(35, 31, 32, 0) 95.87%
    );

    .video-time {
      font-size: 60px;
      line-height: 78px;
      @include theme-aware("color", "color-white", 1);
    }

    .video-ended {
      font-size: 20px;
      line-height: 26px;
      @include theme-aware("color", "color-white", 1);
      width: min-content;

      span {
        margin-left: 10px;
      }
    }
  }

  .video-info {
    z-index: 99999;
    bottom: 0;
    left: 0;
    padding: 40px 40px 20px;
    background: linear-gradient(
      0deg,
      rgba(35, 31, 32, 0.7) 23.75%,
      rgba(35, 31, 32, 0.48) 57.56%,
      rgba(35, 31, 32, 0) 95.87%
    );
    transition: all 400ms ease;

    &[data-is-hidden="true"] {
      transform: translateY(calc(100% - 60px));
    }

    .video-line {
      height: 5px;
      @include theme-aware("background-color", "color-white", 0.3);
      margin-bottom: 24px;
      border-radius: 19px;

      .watched-line {
        height: 100%;
        @include theme-aware("background-color", "color-white", 1);
        border-radius: 19px;
        width: var(--watched);
      }

      .time-point {
        width: 13px;
        height: 13px;
        @include theme-aware("background-color", "color-white", 1);
        top: 50%;
        transform: translate(-50%, -50%);
        left: var(--watched);
      }

      .buffer-line {
        height: 100%;
        @include theme-aware("background-color", "color-white", 0.4);
        border-radius: 19px;
        width: var(--buffered);
      }
    }

    .info-area {
      .trainer-info {
        .avatar {
          width: 60px;
          height: 60px;

          img {
            object-fit: cover;
          }
        }

        .info {
          .workout-title {
            font-size: 16px;
            line-height: 20px;
            @include theme-aware("color", "color-white", 1);
          }

          .trainer-name {
            font-size: 14px;
            line-height: 20px;
            @include theme-aware("color", "color-white", 1);
          }
        }
      }
    }

    .action-button {
      margin-right: 24px;
    }

    .video-actions {
      .live-indicator {
        @include theme-aware("color", "color-red", 1);
        margin-right: 30px;
      }
    }
  }

  .close-button {
    padding: 10px 68px;
    font-size: 18px;
    line-height: 20px;
  }

  &-enter {
    opacity: 0;
  }

  &-enter-active,
  &-enter-done {
    opacity: 1;
    transition: all 300ms;
  }

  &-exit {
    opacity: 1;
  }

  &-exit-active,
  &-exit-done {
    opacity: 0;
    transition: all 300ms;
  }

  .premium-error {
    top: 0;
    left: 0;
    z-index: 9999;
    @include theme-aware("background-color", "color-gray-dark", 1);
    padding: 30px;
    @include theme-aware("color", "color-gray-light", 1);

    .logo {
      height: 70px;
      top: 30px;
      left: 50%;
      transform: translateX(-50%);

      @media screen and (max-height: 420px) {
        height: 48px;
      }
    }

    .content-area {
      max-width: 600px;

      h3 {
        margin-bottom: 40px;
        font-size: 22px;

        @include media-breakpoint-up(md) {
          font-size: 28px;
        }
      }

      p {
        font-size: 16px;
        line-height: 1.4;
        margin-bottom: 15px;

        @include media-breakpoint-up(md) {
          font-size: 18px;
        }
      }

      .UAButton {
        margin-top: 50px;
        max-width: max-content;
      }
    }
  }

  &[data-is-live="true"] {
    .video-info {
      &[data-is-hidden="true"] {
        transform: translateY(100%);
      }
    }
  }
}

body {
  .Workouts {
    .categories,
    .container {
      transition: filter 200ms;
    }
  }
  &.login-modal-open {
    .Workouts {
      .categories,
      .container {
        filter: blur(4px);
      }
    }
  }
}
