.UAConfirmModal {
  top: 0;
  left: 0;
  z-index: 1000;

  .modal-overlay {
    top: 0;
    left: 0;
    @include theme-aware("background-color", "color-black", 0.6);
    z-index: 1;
  }

  .modal-content {
    z-index: 2;
    max-width: 420px;
    @include theme-aware("background-color", "color-white", 1);
    border-radius: 8px;
    padding: 24px;

    .modal-header {
      margin-bottom: 10px;

      .modal-title {
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0em;
        @include theme-aware("color", "color-text", 1);
      }
    }

    .modal-body {
      margin-bottom: 10px;
      p {
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0em;
        @include theme-aware("color", "color-text", 1);
        margin-bottom: 10px;
      }
    }

    .modal-footer {
      margin-top: 10px;

      .UAButton {
        font-size: 16px;
        padding: 8px 12px;
        margin-left: 8px;
        border-radius: 8px;
      }
    }
  }
}
