$border-radius: 14px;

.UAWorkout {
  width: 340px;
  height: 170px;
  border-radius: $border-radius;

  .workout-bg {
    top: 0;
    left: 0;
    background: transparent no-repeat;
    background-size: cover;
    border-radius: $border-radius;
  }

  .workout-details {
    padding: 29px 11px 16px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 3.33%,
      rgba(255, 255, 255, 0.69) 28.64%,
      #ffffff 64.19%
    );
    border-radius: 0 0 $border-radius $border-radius;

    .title {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 5px;
    }

    .bottom {
      .left {
        %text-style {
          font-size: 10px;
          line-height: 15px;
        }
        .duration {
          @extend %text-style;
          @include theme-aware("color", "color-green-dark", 1);
        }

        .trainer {
          @extend %text-style;
          @include theme-aware("color", "color-gray-dark", 1);
          margin: 0 15px;
        }

        .starts-at {
          @extend %text-style;
        }
      }
    }
  }
}
