.LoginWithSocialMedias {
  max-width: 100%;

  @include media-breakpoint-up(md) {
    max-width: 300px;
  }

  .social-media-container {
    padding: 12px 0 24px;
  }

  .alternative {
    font-size: 1rem;
  }

  .icon {
    cursor: pointer;
    height: 40px;

    img {
      height: 40px;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  .icon.apple-icon {
    cursor: pointer;
    height: 44px;

    img {
      height: 44px;
    }
  }
}
