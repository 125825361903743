.Login {
  form {
    @include media-breakpoint-up(xl) {
      padding: 0 35px;
    }

    .UAInput {
      margin-bottom: 32px;

      &:last-of-type {
        margin-bottom: 26px;
      }

      &[data-is-error="true"] {
        margin-bottom: 52px;
      }
    }

    .UAButton {
      margin-top: 48px;
      margin-bottom: 36px;
      padding: 15px 50px;
    }
  }

  .already-user {
    font-family: "Open Sans", sans-serif;
  }
}
