.UAProfileShort {
  padding: 30px 15px;
  @include theme-aware("background-color", "profile-background", 1);
  border-radius: 8px;
  margin-bottom: 30px;

  @include media-breakpoint-up(md) {
    padding: 30px;
  }

  .avatar {
    width: 64px;
    height: 64px;
    border: 3px solid;
    @include theme-aware("border-color", "color-green", 1);
    img {
      object-fit: cover;
    }

    @include media-breakpoint-up(md) {
      width: 124px;
      height: 124px;
    }
  }

  .user-info-area {
    margin-left: 15px;

    @include media-breakpoint-up(md) {
      margin-left: 30px;
    }

    .user-name {
      font-size: 22px;
      line-height: 28px;

      @include media-breakpoint-up(md) {
        font-size: 28px;
        line-height: 20px;
        margin-bottom: 30px;
      }
    }

    .info-holder {
      .info-item {
        font-size: 20px;
        letter-spacing: 0em;
        border-right: 1px solid;
        @include theme-aware("border-color", "color-gray", 1);
        padding-right: 15px;
        margin-right: 15px;

        span {
          font-size: 12px;
          margin-top: 3px;
        }

        @include media-breakpoint-up(md) {
          font-size: 24px;
          margin-right: 40px;
          padding-right: 40px;
        }

        &:last-child {
          border-right: none;
          padding-right: 0;
          margin-right: 0;
        }
      }
    }
  }

  .SocialModal {
    padding: 48px 20px 30px;

    .social-title {
      font-size: 38px;
      margin-bottom: 28px;
    }

    .follows-holder {
      .title {
        font-size: 14px;
        line-height: 20px;
        margin-top: 30px;
        margin-bottom: 13px;
      }

      .users {
        @include theme-aware("background-color", "color-white", 1);
        border-radius: 10px;
        padding: 0 10px 15px 20px;
        max-height: 40vh;

        .user {
          border-bottom: 1px solid;
          @include theme-aware("border-color", "color-gray-light", 1);
          padding: 13px 0;

          &:last-child {
            border-bottom: none;
            padding-bottom: 0;
          }

          .left {
            .avatar {
              width: 40px;
              height: 40px;
              border: 2px solid;
              @include theme-aware("border-color", "color-green-light", 1);
            }

            .user-info {
              margin-left: 11px;

              .name {
                font-size: 16px;
                margin-bottom: 2px;
              }

              .username {
                font-size: 16px;
                margin-bottom: 0;
              }
            }
          }
          .right {
            .follow-button {
              font-size: 14px;
              padding: 10px 20px;
            }
          }
        }
      }
    }
  }
}
