.Account {
  .update-password {
    .UAInput {
      margin: 30px 0;
    }
  }

  .agreement-holder {
    max-height: 150px;
    @include theme-aware("background-color", "color-white", 1);
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 30px;
  }

  .agreement-modal {
    padding: 60px 34px 20px;

    .agreement-title {
      @include theme-aware("color", "color-black", 1);
      font-size: 24px;
      line-height: 20px;
      margin-bottom: 40px;
    }

    .agreement-content {
      border-radius: 8px;
      padding: 20px;
      max-height: 630px;
      margin-bottom: 30px;
    }

    .UAButton {
      font-size: 18px;
      font-weight: 600;
      line-height: 20px;
      padding: 14px 34px;
    }
  }
}
