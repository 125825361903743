/**
 * theme-aware - Change color of a css property based on the currently active theme
 *
 * @param  {key}     CSS property
 * @param  {color}   Color name defined in the themes under _themes.scss
 * @param  {opacity} Opacity value for rgba color
 * @param  {extra}   Extra parameter if needed
 *
 * @example - @include theme-aware('background', 'color-background');
 * @returns - background: rgba(255, 255, 255, 1);
 *
 * @example - @include theme-aware('background', 'color-background', 0.4);
 * @returns - background: rgba(255, 255, 255, 0.4);
 *
 * @example - @include theme-aware('background', 'color-background', 1, simple);
 * @returns - background: #FFF;
 */
@mixin theme-aware($key, $color, $opacity: 1, $extra: "") {
  @each $theme-name, $theme-color in $themes {
    @if map-has-key($map: map-get($themes, $theme-name), $key: $color) {
      .theme-#{$theme-name} & {
        @if $extra == "simple" {
          #{$key}: map-get(map-get($themes, $theme-name), $color);
        } @else if $extra != "" {
          #{$key}: rgba(
              map-get(map-get($themes, $theme-name), $color),
              $opacity
            )
            $extra;
        } @else {
          #{$key}: rgba(
            map-get(map-get($themes, $theme-name), $color),
            $opacity
          );
        }
      }
    } @else {
      @error "Theme '#{$theme-name}' does not have a color named '#{$color}'";
    }
  }
}
