.GlobalLayout {
  padding-bottom: 64px;

  @include media-breakpoint-up(lg) {
    padding-bottom: 0;
  }

  header {
    @include theme-aware("background-color", "color-white", 1);
    padding: 21px;
    z-index: 2;

    .logo {
      height: 38px;
      margin-top: -8px;
    }

    .nav {
      .nav-link {
        margin-left: 15px;
        font-size: 14px;
        font-weight: $font-weight-semibold;
        line-height: 20px;
        padding: 0 10px;
        @include theme-aware("color", "color-gray-dark", 1);
        border-bottom: 2px solid transparent;

        @include media-breakpoint-up(xl) {
          margin-left: 30px;
        }

        &:first-of-type {
          margin-left: 0;
        }

        &.active {
          @include theme-aware("border-color", "color-green", 1);
        }
      }
    }

    .guest-area {
      .UAButton {
        padding: 4px 20px;
        font-size: 14px;

        @include media-breakpoint-up(xl) {
          font-size: 22px;
        }
      }
    }

    .user-area {
      z-index: 9;

      .user-avatar {
        width: 40px;
        height: 40px;
        margin-right: 10px;

        img {
          object-fit: cover;
        }

        .default-avatar {
          @include theme-aware("background-color", "color-text", 1);
        }
      }

      .user-name {
        margin-right: 10px;
      }

      .menu-trigger {
        .icon {
          transform: rotate(0);
          transition: transform 200ms;
        }
      }

      .user-menu {
        width: 180px;
        right: -14px;
        bottom: 0;
        transform: translateY(calc(100% + 5px));
        padding: 10px 20px;
        @include theme-aware("background-color", "color-white", 1);
        border-radius: 8px;
        box-shadow: 0px 0px 15px 0px rgba($color: #000000, $alpha: 0.15);

        &::before {
          content: "";
          width: 10px;
          height: 10px;
          @include theme-aware("background-color", "color-white", 1);
          left: 50%;
          top: 0;
          transform: translate(-50%, -50%) rotate(45deg);
          position: absolute;
          z-index: 1;
        }

        &-enter {
          opacity: 0;
          transform: translateY(calc(100% - 10px));
        }

        &-enter-active,
        &-enter-done {
          opacity: 1;
          transform: translateY(calc(100% + 5px));
          transition: all 300ms;
        }

        &-exit {
          opacity: 1;
          transform: translateY(calc(100% + 5px));
        }

        &-exit-active,
        &-exit-done {
          opacity: 0;
          transform: translateY(calc(100% - 10px));
          transition: all 300ms;
        }

        .list-item {
          padding: 7px 0;
          font-size: 14px;
          font-weight: $font-weight-semibold;
          line-height: 20px;
          text-transform: uppercase;
          @include theme-aware("color", "color-gray-dark", 1);

          &.logout {
            margin-top: 7px;
            padding-top: 14px;
            border-top: 1px solid;
            @include theme-aware("border-color", "color-gray-light", 0.5);
          }
        }
      }

      &[data-is-active="true"] {
        .menu-trigger {
          .icon {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  .mobile-nav {
    bottom: 0;
    left: 0;
    height: 64px;
    @include theme-aware("background-color", "color-white", 1);
    box-shadow: 0px -4px 13px 0px #0000000f;
    z-index: 9999;
    padding: 14px;

    .mobile-nav-link {
      font-size: 10px;
      line-height: 12px;
      text-align: center;
      opacity: 0.4;
      transition: opacity 200ms;
      width: 50%;
      margin-bottom: 10px;

      span {
        opacity: 0;
        transition: all 200ms;
        position: absolute;
        left: 50%;
        bottom: -2px;
        transform: translate(-50%, 100%);
      }

      img {
        margin-bottom: 2px;
      }

      &.active {
        opacity: 1;

        span {
          opacity: 1;
        }
      }
    }

    .middle {
      margin: 0 10px;
      .home-link {
        width: 68px;
        height: 68px;
        @include theme-aware("background-color", "color-green", 1);

        &.active {
          @include theme-aware("background-color", "color-gray-dark", 1);
        }
      }
    }
  }

  .page-content {
    padding-bottom: 40px;
  }

  footer {
    @include theme-aware("background-color", "color-white", 1);

    .section-title {
      font-size: 18px;
      font-weight: $font-weight-semibold;
      line-height: 20px;
      margin-bottom: 15px;
    }

    .top {
      padding: 20px 13px 17px;

      .left {
        .logo {
          height: 38px;
          margin-bottom: 15px;

          img {
            margin-top: -9px;
          }
        }

        .lang-select {
          .lang-button {
            width: 28px;
            height: 28px;
            opacity: 0.5;
            transition: opacity 200ms;

            &:hover {
              opacity: 0.8;
            }

            &[data-active="true"] {
              opacity: 1;
            }

            img {
              object-fit: cover;
            }
          }
        }

        .footer-nav {
          ul {
            padding: 0;
            margin: 0 32px 0 0;
            list-style: none;

            li {
              margin-bottom: 19px;
            }
          }
        }
      }

      .middle {
        max-width: 400px;

        .input-area {
          margin-bottom: 16px;

          .UAInput {
            margin-right: 13px;
          }

          .UAButton {
            padding: 4px 20px;
            white-space: nowrap;
          }
        }

        .disclaimer {
          font-size: 12px;
          line-height: 18px;
        }
      }
    }

    .bottom {
      @include theme-aware("background-color", "footer-bottom-background", 1);
      padding: 20px 13px 17px;

      .top-section {
        .agreements {
          @include media-breakpoint-up(md) {
            width: 50%;
          }

          a {
            margin-right: 15px;

            &:last-of-type {
              margin-right: 0;
            }
          }
        }

        .disclaimer {
          font-weight: $font-weight-semibold;
          line-height: 20px;
        }

        .socials {
          @include media-breakpoint-up(md) {
            width: 50%;
          }

          a {
            margin-left: 22px;
            font-size: 20px;

            &:first-of-type {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}
