.Register {
  form {
    @include media-breakpoint-up(xl) {
      padding: 0 35px;
    }

    .UAInput {
      margin-bottom: 32px;

      &[data-is-error="true"] {
        margin-bottom: 52px;
      }
    }

    .form-submit-button {
      margin-top: 12px;
      margin-bottom: 50px;
      padding: 15px 50px;
    }
  }

  .already-user {
    font-family: "Open Sans", sans-serif;
  }
}
