.UAInput {
  width: 100%;

  .country-code-area {
    $countryCodeWidth: 42px;

    @include theme-aware("background-color", "color-white", 1);
    border-radius: 8px;
    padding: 14px 12px;
    transition: all 200ms;
    margin-right: 10px;

    .country-flag {
      left: 12px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 20px;
    }

    input {
      width: $countryCodeWidth;
      margin-left: 24px;
    }

    & ~ .input-area {
      label {
        left: calc(12px + 24px + $countryCodeWidth + 10px + 12px * 2);
      }
    }
  }

  .input-area {
    @include theme-aware("background-color", "color-white", 1);
    border-radius: 8px;
    padding: 14px 12px;
    transition: all 200ms;

    input {
      @include theme-aware("color", "color-text", 1);

      &:-webkit-autofill {
        animation-name: onAutoFillStart;
        transition: background-color 50000s ease-in-out 0s;
      }

      &:not(:-webkit-autofill) {
        animation-name: onAutoFillCancel;
      }
    }
  }

  &[data-is-bordered="true"] {
    .input-area {
      border: 1px solid;
      @include theme-aware("border-color", "color-gray-light", 1);
    }
  }

  label {
    @include theme-aware("color", "color-gray", 1);
    font-family: "Open Sans", sans-serif;
    font-size: 18px;
    font-weight: $font-weight-medium;
    line-height: 22px;
    letter-spacing: 0px;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
    width: calc(100% - 12px * 2);
    transition: all 200ms;

    &[data-is-filled="true"] {
      top: 0;
      transform: translateY(calc(-100% - 4px));
      font-size: 14px;
      font-weight: $font-weight-light;
      line-height: 16px;
    }
  }

  .icon {
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
  }

  .UAInput-error-message {
    bottom: 0;
    left: 12px;
    transform: translateY(calc(100% + 4px));
    font-family: "Open Sans";
    font-size: 12px;
    line-height: 16px;
  }

  &[data-theme="dark"] {
    .input-area,
    .country-code-area {
      @include theme-aware("background-color", "color-blueish", 1);
    }

    label {
      @include theme-aware("color", "color-black", 1);
    }

    input {
      @include theme-aware("background-color", "color-blueish", 1);
      @include theme-aware("color", "color-text", 1);
    }
  }
}
