.UAModal {
  top: 0;
  left: 0;
  padding: 20px 0;
  z-index: 999;

  .overlay {
    top: 0;
    left: 0;
    opacity: 0;
    @include theme-aware("background-color", "color-black", 0.4);
  }

  .content {
    @include theme-aware("background-color", "modal-background", 1);
    max-width: 95vw;
    max-height: calc(95vh - 74px);
    // overflow-y: auto;
    opacity: 0;
    transform: translateY(-75px);

    @include media-breakpoint-up(lg) {
      max-height: 94vh;
    }

    .close-btn {
      z-index: 5;
    }

    @include media-breakpoint-up(md) {
      max-width: 708px;
    }
  }

  &-enter {
    .overlay {
      opacity: 0;
    }

    .content {
      opacity: 0;
      transform: translateY(-75px);
    }
  }

  &-enter-active,
  &-enter-done {
    .overlay {
      opacity: 1;
      transition: opacity 300ms;
    }

    .content {
      opacity: 1;
      transform: translateY(-32px);
      transition: all 300ms;

      @include media-breakpoint-up(lg) {
        transform: translateY(0);
      }
    }
  }

  &-exit {
    .overlay {
      opacity: 1;
    }

    .content {
      opacity: 1;
      transform: translateY(-32px);

      @include media-breakpoint-up(lg) {
        transform: translateY(0);
      }
    }
  }

  &-exit-active,
  &-exit-done {
    .overlay {
      opacity: 0;
      transition: opacity 300ms;
    }

    .content {
      opacity: 0;
      transform: translateY(-75px);
      transition: all 300ms;
    }
  }
}
