.Programs {
  padding: 27px 0;

  .programs-list {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 30px;
    column-gap: 30px;

    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(4, 1fr);
      row-gap: 50px;
    }

    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(5, 1fr);
      column-gap: 65px;
    }

    .Program {
      transform: translateY(0);
      transition: all 200ms;

      &:hover {
        transform: translateY(-10px);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
      }
    }
  }

  .Program {
    height: 260px;
    width: 100%;

    .program-bg {
      background-position: center;
      background-size: cover;
      border-radius: 8px;
    }

    .detail-link {
      width: 48px;
      height: 36px;
      top: 0;
      right: 0;
      border-radius: 0 8px 0 8px;
      @include theme-aware("background-color", "color-gray-dark", 0.3);
    }

    .info-area {
      padding: 49px 10px 14px;
      background: linear-gradient(
        175.91deg,
        rgba(255, 255, 255, 0) 3.33%,
        rgba(255, 255, 255, 0.69) 28.64%,
        #ffffff 64.19%
      );
      border-radius: 0 0px 8px 8px;

      .program-title {
        font-size: 14px;
        line-height: 20px;
        min-height: 41px;
        margin-bottom: 5px;
      }

      .more-info {
        %text-style {
          font-size: 12px;
          line-height: 18px;
        }

        .program-days {
          @extend %text-style;
          @include theme-aware("color", "color-green-dark", 1);
        }

        .program-difficulty {
          @extend %text-style;
          @include theme-aware("color", "color-gray-alt", 1);
        }
      }
    }
  }

  .ProgramDetail {
    padding: 101px 0 30px;
    @include theme-aware("background-color", "program-background", 1);
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 75%;

    .program-info {
      background: linear-gradient(
        180.79deg,
        rgba(255, 255, 255, 0) 18.83%,
        rgba(255, 255, 255, 0.69) 46.73%,
        #ffffff 65.22%
      );
      padding: 139px 19px 19px;
      z-index: 2;

      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: var(--program-bg-color);
        z-index: -1;
      }

      .title {
        font-size: 38px;
        line-height: 49px;
        margin-bottom: 9px;
      }

      .sub-info {
        margin-bottom: 15px;

        .info {
          font-size: 18px;
          line-height: 20px;
          margin-right: 25px;

          &--days {
            @include theme-aware("color", "color-green-dark", 1);
          }

          &--trainer {
            @include theme-aware("color", "color-gray-alt", 1);
          }
        }
      }

      .desc {
        margin-bottom: 0;
        font-size: 16px;
        line-height: 20px;
      }
    }

    .program-workouts {
      padding: 19px;
      @include theme-aware("background-color", "program-background", 1);
      grid-template-columns: 1fr;
      row-gap: 19px;
      margin-bottom: 30px;

      @include media-breakpoint-up(md) {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 34px;
      }

      .workout-card {
        height: 332px;
        border-radius: 8px;
        background: transparent no-repeat;
        background-size: cover;
        background-position: center;

        .day {
          top: 0;
          right: 0;
          width: 100px;
          height: 45px;
          @include theme-aware("background-color", "color-gray-alt", 1);
          @include theme-aware("color", "color-white", 1);
          font-size: 18px;
          line-height: 20px;
          border-radius: 0 8px 0 8px;
        }

        .workout-info {
          background: linear-gradient(
            175.91deg,
            rgba(255, 255, 255, 0) 3.33%,
            rgba(255, 255, 255, 0.69) 28.64%,
            #ffffff 64.19%
          );
          padding: 76px 18px 15px;
          border-radius: 0 0 8px 8px;

          .title {
            font-size: 18px;
            line-height: 20px;
            margin-bottom: 25px;
            min-height: 40px;
          }

          .trainer {
            font-size: 16px;
            line-height: 20px;
            @include theme-aware("color", "color-gray-alt", 1);
          }
        }

        .start-button {
          bottom: 0;
          right: 0;
          width: 120px;
          height: 45px;
          font-size: 18px;
          line-height: 20px;
          @include theme-aware("background-color", "color-yellow", 1);
          border-radius: 8px 0 8px 0;

          svg {
            margin-left: 16px;
            font-size: 13px;
          }
        }
      }
    }

    .close-button {
      padding: 15px 35px;
    }
  }
}
