.Profile {
  .success-popup {
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);
    @include theme-aware("background-color", "color-black", 0.65);
    border-radius: 8px;
    padding: 20px;
    width: 418px;
    z-index: 4;
    font-size: 14px;
    line-height: 20px;
    opacity: 1;
    transition: all 300ms;

    &-enter {
      opacity: 0;
      transform: translate(-50%, -150%);
    }

    &-enter-active {
      opacity: 1;
      transform: translate(-50%, -100%);
    }

    &-exit {
      opacity: 1;
      transform: translate(-50%, -100%);
    }

    &-exit-active {
      opacity: 0;
      transform: translate(-50%, -150%);
    }
  }

  .avatar-holder {
    border: 3px solid;
    @include theme-aware("border-color", "color-green", 1);
    width: 160px;
    height: 160px;

    &[data-is-updating="true"] {
      filter: grayscale(100%);
    }

    .updating {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.8) 0%,
        rgba(0, 0, 0, 0.3) 100%
      );
      border-radius: 50%;
    }

    img {
      object-fit: cover;
    }

    label {
      width: 40px;
      height: 40px;
      @include theme-aware("background-color", "color-green", 1);
      border: 4px solid;
      @include theme-aware("border-color", "color-blueish", 1);
      bottom: 0;
      right: 0;
    }

    input {
      &:disabled {
        & + label {
          pointer-events: none;
          filter: grayscale(100%);
          opacity: 0.6;
        }
      }
    }
  }

  section {
    border-bottom: 1px solid;
    @include theme-aware("border-color", "color-gray", 1);
    padding: 0 0 30px;
    margin-bottom: 30px;

    &:first-child {
      padding-top: 0;
    }

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }

    .title {
      font-size: 18px;
      line-height: 20px;
      margin-bottom: 20px;
    }

    .UAInput {
      label {
        transform: translateY(calc(-100% - 7px));
        top: 0;
        left: 0;
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
      }
    }

    .save-button {
      width: 180px;
      padding: 12px 0;
      font-size: 16px;
      font-weight: 500;
      line-height: 15px;
    }
  }

  &.extra-padding {
    section {
      padding: 30px 0;
    }
  }
}
