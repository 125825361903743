.UALoginModal {
  top: 0;
  left: 0;
  z-index: 9999;

  .overlay {
    top: 0;
    left: 0;
    z-index: 1;
    @include theme-aware("background-color", "color-black", 0.3);
    filter: blur(10px);
  }

  .modal-content {
    max-width: 550px;
    padding: 30px;
    z-index: 3;
    box-shadow: 0 0 20px 5px rgba($color: #000000, $alpha: 0.15);

    .close-btn {
      top: 15px;
      right: 15px;
    }

    .page-title {
      font-family: $font-family-sec;
      font-size: 32px;
      font-weight: $font-family-base;
      line-height: 32px;
      margin-bottom: 35px;
    }
  }

  &-enter {
    .overlay {
      opacity: 0;
    }

    .modal-content {
      opacity: 0;
      transform: translateY(-75px);
    }
  }

  &-enter-active,
  &-enter-done {
    .overlay {
      opacity: 1;
      transition: opacity 300ms;
    }

    .modal-content {
      opacity: 1;
      transform: translateY(0);
      transition: all 300ms;
    }
  }

  &-exit {
    .overlay {
      opacity: 1;
    }

    .modal-content {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  &-exit-active,
  &-exit-done {
    .overlay {
      opacity: 0;
      transition: opacity 300ms;
    }

    .modal-content {
      opacity: 0;
      transform: translateY(-75px);
      transition: all 300ms;
    }
  }
}

body {
  header,
  footer {
    transition: filter 200ms;
  }
  &.login-modal-open {
    header,
    footer,
    .UAModal {
      filter: blur(4px);
    }
  }
}
