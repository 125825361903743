.UASelect {
  height: 100%;

  &__container {
    label {
      top: 0;
      left: 0;
      transform: translateY(calc(-100% - 7px));
      font-size: 12px;
      line-height: 18px;
    }
  }

  .UASelect__control {
    cursor: pointer;
  }

  .UASelect__single-value {
    height: 16px;
  }

  .UASelect__menu {
    z-index: 2 !important;
  }

  .UASelect__option:hover {
    cursor: pointer !important;
  }

  .UASelect__control,
  .UASelect__control:hover {
    @include theme-aware("background-color", "color-blueish", 1);
    border-radius: 8px;
    border: none;
    height: 100%;
    box-shadow: none;
  }
}
