.GuestLayout {
  background-image: url(../../../assets/static/guest-layout-footer.svg);
  background-position: center bottom;
  background-repeat: no-repeat;
  padding: 70px 0 50px;

  .content {
    grid-auto-rows: max-content 1fr max-content;

    .guest-layout-header {
      .page-title {
        font-family: $font-family-sec;
        font-size: 32px;
        font-weight: $font-family-base;
        line-height: 32px;
        margin: 95px 0 35px;
      }
    }

    .page-content {
      margin: 0 0 110px;
    }

    .sm-holder {
      margin-bottom: 20px;
      .sm-item {
        margin: 0 5px;
        padding: 5px 8px;
        font-size: 18px;

        @include media-breakpoint-up(md) {
          margin: 0 13px;
          padding: 0;
        }
      }
    }

    .footer-nav {
      margin-bottom: 20px;
      .footer-nav-link {
        margin: 0 7px;
        padding: 0 7px;
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
}
