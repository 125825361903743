.Trainers {
  padding: 27px 25px 46px;

  @include media-breakpoint-up(md) {
    padding: 27px 0 46px;
  }

  .page-title {
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 27px;
  }

  .trainers-list {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 50px;
    column-gap: 20px;

    @include media-breakpoint-up(sm) {
      grid-template-columns: repeat(3, 1fr);
    }

    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(4, 1fr);
      column-gap: 72px;
    }

    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(5, 1fr);
    }

    .Trainer {
      .trainer-image {
        @include theme-aware("background-color", "trainer-background", 1);
        padding: 28px 22px;
        border-radius: 8px;
        margin-bottom: 14px;
        transform: translateY(0);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        transition: all 300ms;

        img {
          object-fit: cover;
        }
      }

      .trainer-name {
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0em;
        margin-bottom: 3px;

        @include media-breakpoint-up(md) {
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 7px;
        }
      }

      .trainer-title {
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0em;

        @include media-breakpoint-up(md) {
          font-size: 16px;
          line-height: 20px;
        }
      }

      &:hover {
        .trainer-image {
          transform: translateY(-5px);
          box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}

.TrainerDetail {
  padding-top: 300px;

  .trainer-image {
    top: 0;
    left: 0;
    background-image: url("../../../assets/static/icons/uaLogo.svg");
    background-repeat: no-repeat;
    background-size: calc(100% - 65px);
    background-position: top 90px center;
    margin-top: 30px;

    img {
      max-width: 342px;
    }
  }

  .trainer-info-area {
    padding: 0 29px;
    background: linear-gradient(
      0deg,
      #011520 14.74%,
      #011520 54.4%,
      rgba(1, 21, 32, 0.48) 78.29%,
      rgba(1, 21, 32, 0) 95.87%
    );

    .name {
      max-width: 245px;
      font-family: $font-family-sec;
      font-size: 38px;
      line-height: 49px;
      margin-bottom: 25px;
    }

    .bio,
    .motto {
      font-size: 13px;
      line-height: 20px;
      @include theme-aware("color", "color-white", 1);
      margin-bottom: 20px;
    }

    .socials {
      margin-bottom: 22px;

      .social-link {
        width: 50px;
        height: 50px;
        font-size: 20px;
        margin-right: 18px;
      }
    }

    .latest-workouts {
      width: calc(100% + 58px);
      margin-left: -29px;

      .top {
        margin-bottom: 10px;
        padding: 0 29px;
      }

      .workouts-slider-holder {
        padding: 29px 0 25px;
        .workouts-slider {
          width: max-content;

          .UAWorkout {
            margin: 0 10px;

            &:first-of-type {
              margin-left: 20px;
            }

            &:last-of-type {
              margin-right: 20px;
            }
          }
        }
      }
    }
  }
}
