.SubPageLayout {
  .page-nav {
    @include theme-aware("background-color", "color-gray", 1);
    padding: 4px 0;
    margin-bottom: 20px;

    .item {
      padding: 4px 15px;
      font-size: 14px;
      line-height: 20px;
      background-color: transparent;
      border-radius: 4px;
      margin: 0 20px;
      transition: all 200ms;

      &.active {
        font-weight: $font-weight-semibold;
        @include theme-aware("background-color", "color-sec", 1);
      }
    }
  }

  .page-content {
    padding: 30px;
    @include theme-aware("background-color", "profile-background", 1);
    border-radius: 8px;
    max-width: 700px;
  }
}
