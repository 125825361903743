.Leadership {
  .leadership-holder {
    .page-title {
      font-size: 26px;
      line-height: 28px;
      letter-spacing: 0em;

      @include media-breakpoint-up(md) {
        font-size: 38px;
        line-height: 38px;
      }
    }

    .top-places {
      margin-top: 35px;

      .UATopPlacePerson {
        margin: 0 8px;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .leadership-rest {
      @include theme-aware("background-color", "color-white", 1);
      padding: 0 10px;
      border-radius: 10px;
      margin-top: 30px;

      .UALeadershipPerson {
        padding: 15px 0;
        border-bottom: 1px solid;
        @include theme-aware("border-color", "color-gray-light", 1);

        &:last-child {
          border-bottom: none;
        }
      }
    }

    .show-more-button {
      padding: 12px 15px;
      margin-top: 30px;

      .label {
        font-size: 16px;
      }

      @include media-breakpoint-up(md) {
        padding: 12px 110px;
      }
    }
  }
}
