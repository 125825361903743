.Paywall {
  @include theme-aware("background-color", "modal-background", 1);

  &__image {
    &__title {
      padding: 16px 0;
      z-index: 2;
      bottom: 40px;
      left: 0;
      font-size: 36px;
      line-height: 50px;
      font-weight: $font-weight-bold;
      @include theme-aware("color", "color-light", 1);
    }
  }

  &__content {
    padding: 40px 60px 25px;

    &__section {
      margin-bottom: 16px;

      &--title {
        font-size: 24px;
        line-height: 20px;
        font-weight: $font-weight-bold;
        margin-bottom: 16px;
        @include theme-aware("color", "color-black", 1);
      }

      .packages {
        .package-item {
          @include theme-aware("background-color", "color-white", 1);
          border-radius: 8px;
          padding: 14px 16px;
          margin-bottom: 16px;
          border: 1px solid;
          @include theme-aware("border-color", "color-white", 1);
          transition: all 200ms;

          .selected-icon {
            width: 28px;
            height: 28px;
            @include theme-aware("background-color", "color-gray-light", 1);
            border-radius: 50%;
            margin-right: 14px;
            border: 3px solid transparent;
            transition: all 200ms;
          }

          .package-term {
            .package {
              line-height: 20px;
              @include theme-aware("color", "color-black", 1);

              &-title {
                font-size: 20px;
                font-weight: $font-weight-bold;
                margin-bottom: 2px;
              }

              &-subtitle {
                font-size: 14px;
                font-weight: $font-weight-regular;
              }
            }
          }

          .package-price {
            @include theme-aware("color", "color-black", 1);

            .price {
              font-size: 16px;
              line-height: 20px;
              font-weight: $font-weight-bold;
              margin-bottom: 2px;

              .number {
                font-size: 20px;
              }
            }

            .price-description {
              font-size: 14px;
              line-height: 20px;
              font-weight: $font-weight-regular;
            }
          }

          &[data-active="true"] {
            @include theme-aware("background-color", "color-sec", 1);
            @include theme-aware("border-color", "color-pri", 1);

            .selected-icon {
              @include theme-aware("background-color", "color-white", 1);
              border: 3px solid;
              @include theme-aware("border-color", "color-pri", 1);
            }
          }
        }
      }

      .summary {
        &__item {
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 8px;
          @include theme-aware("color", "color-black", 1);

          &--title {
            font-weight: $font-weight-regular;
          }

          &__total {
            font-weight: $font-weight-semibold;
            font-size: 18px;
            border-bottom: 1px solid;
            @include theme-aware("border-color", "color-black", 1);
            padding-bottom: 8px;

            &--title {
              font-weight: $font-weight-semibold;
              font-size: 18px;
            }
          }
        }
      }

      .card-form {
        .input-grp {
          width: 100%;
          margin-bottom: 16px;

          label {
            font-size: 12px;
            line-height: 18px;
            font-weight: $font-weight-semibold;
            @include theme-aware("color", "color-black", 1);
            margin-bottom: 7px;
            width: 100%;

            input,
            select {
              width: 100%;
              padding: 17px 22px;
              border-radius: 8px;
              @include theme-aware("background-color", "color-blueish", 1);
              font-size: 16px;
              line-height: 20px;
              font-weight: $font-weight-regular;
              @include theme-aware("color", "color-black", 1);
            }

            .select-group {
              gap: 8px;
              select {
                width: 50%;
                background-image: url("../../../assets/static/icons/down-caret.svg");
                background-repeat: no-repeat;
                background-position: right 16px center;
                background-size: 16px;
                padding-right: 40px;
              }
            }
          }
        }
      }
    }

    .pay-button {
      font-size: 16px;
      line-height: 20px;
      font-weight: $font-weight-bold;
    }

    .footnote-content {
      .first {
        font-size: 13px;
        line-height: 16px;
        font-weight: $font-weight-bold;
        @include theme-aware("color", "color-text", 1);
        margin-bottom: 20px;
      }

      .last {
        font-size: 11px;
        line-height: 14px;
        font-weight: $font-weight-regular;
        @include theme-aware("color", "color-gray-alt", 1);
      }
    }
  }
}

.UAModal {
  &.paywall-modal {
    .content {
      @include media-breakpoint-up(lg) {
        max-width: 960px;
      }
    }
  }
}
