.Home {
  .Slider {
    width: 100%;
    margin: 0 auto 15px;
    overflow: hidden;

    @include media-breakpoint-up(lg) {
      min-height: 360px;
      margin-bottom: 50px;
    }

    .swiper {
      .swiper-pagination {
        .swiper-pagination-bullet {
          width: 16px;
          height: 16px;
          @include theme-aware("background-color", "color-gray", 1);
          border: 2px solid;
          @include theme-aware("border-color", "color-gray-dark", 1);

          &-active {
            @include theme-aware("background-color", "color-green-light", 1);
          }
        }
      }
    }
  }
  .section-row {
    section {
      padding: 30px 0;
      border-bottom: 1px solid;
      @include theme-aware("border-color", "color-gray", 1);

      .top {
        margin-bottom: 20px;
        .section-title {
          font-size: 18px;
          line-height: 20px;
        }

        .top-link {
          font-size: 14px;
          line-height: 20px;
          @include theme-aware("color", "color-gray-dark", 1);
        }
      }

      .LiveWorkouts {
        .live-workouts-list {
          grid-template-columns: 1fr;
          row-gap: 20px;
          column-gap: 20px;

          @include media-breakpoint-up(md) {
            grid-template-columns: repeat(2, 1fr);
          }

          @include media-breakpoint-up(lg) {
            grid-template-columns: repeat(3, 1fr);
            column-gap: 30px;
          }

          .live-workout {
            height: 170px;
            width: 100%;
            border-radius: 8px;
            box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.059);

            .workout-image {
              border-radius: 8px;
              img {
                object-fit: cover;
                border-radius: 8px;
              }
            }

            .live-badge {
              top: 0;
              left: 0;
              z-index: 3;
              width: 74px;
              height: 32px;
              border-radius: 8px 0 8px 0;
              font-size: 14px;
              line-height: 20px;
            }

            .workout-difficulty {
              top: 10px;
              right: 10px;
              z-index: 3;
              font-size: 12px;
              line-height: 18px;
            }

            .workout-info {
              padding: 29px 11px 13px;
              background: linear-gradient(
                180deg,
                rgba(255, 255, 255, 0) 3.33%,
                rgba(255, 255, 255, 0.69) 28.64%,
                #ffffff 64.19%
              );
              border-radius: 0 0 8px 8px;

              .workout-title {
                font-size: 16px;
                line-height: 20px;
                margin-bottom: 5px;
              }

              .info-bottom {
                .left {
                  %info-bottom-item {
                    font-size: 12px;
                    font-weight: $font-weight-semibold;
                    line-height: 18px;
                    margin-right: 12px;
                  }
                  .duration {
                    @extend %info-bottom-item;
                    @include theme-aware("color", "color-green-dark", 1);
                  }

                  .trainer {
                    @extend %info-bottom-item;
                    @include theme-aware("color", "color-gray-alt", 1);
                  }

                  .starts-at {
                    @extend %info-bottom-item;
                  }
                }
              }
            }
          }
        }
      }

      .PersonalWorkouts {
        .personal-workouts-list {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          row-gap: 30px;
          column-gap: 20px;

          @include media-breakpoint-up(sm) {
            grid-template-columns: repeat(3, 1fr);
          }

          @include media-breakpoint-up(lg) {
            grid-template-columns: repeat(5, 1fr);
            column-gap: 30px;
            row-gap: 0;
          }

          .workout {
            @include theme-aware("background-color", "color-light", 1);
            border-radius: 8px;
            box-shadow: 0px 4px 24px 0px rgba($color: #000000, $alpha: 0.06);
            transition: all 400ms;
            height: 214px;

            .workout-bg {
              background-image: url("../../../assets/static/vertical-workout-background.svg");
              background-position: center right;
              background-repeat: no-repeat;
              z-index: 2;
            }

            .bookmark-icon {
              top: 12px;
              right: 10px;
            }

            .top {
              margin: 15px 15px 0;
              position: relative;
              z-index: 3;
              padding-right: 15px;

              .title {
                font-size: 13px;
                line-height: 20px;
                min-height: 40px;
                margin-bottom: 5px;
              }

              .duration {
                font-size: 12px;
                line-height: 18px;
                @include theme-aware("color", "color-green-dark", 1);
                margin-bottom: 0;
              }

              .trainer {
                font-size: 12px;
                line-height: 18px;
                @include theme-aware("color", "color-gray-alt", 1);
              }
            }

            .trainer-image {
              z-index: 3;
              padding: 0 50px 0 15px;

              img {
                width: 100%;
                height: calc(100% + 50px + 15px);
                object-fit: cover;
                transform: translateY(calc(50px + 15px));
              }
            }

            .bottom-bg {
              left: 0;
              bottom: 0;
              height: 35%;
              max-height: 72px;
              @include theme-aware("background-color", "color-green-light", 1);
              z-index: 1;
            }

            .play-icon {
              right: 0;
              bottom: 0;
              padding: 9px 14px 9px 18px;
              @include theme-aware("background-color", "color-black", 0.5);
              border-radius: 8px 0;
              z-index: 4;
            }

            &:hover {
              transform: translateY(-6px);
              box-shadow: 0px 9px 28px 0px rgba($color: #000000, $alpha: 0.06);
            }
          }
        }
      }

      .Programs {
        .programs-list {
          @include media-breakpoint-up(lg) {
            column-gap: 30px;
          }
        }
      }
    }

    &:last-of-type {
      section {
        border-bottom: none;
        padding-bottom: 0;
      }
    }
  }
}
